var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mx-3 pt-4"
  }, [_c("PatientHistory", {
    attrs: {
      show: "",
      "patient-id": "0d78f426-9d71-49fe-9b80-831c9bf2de0e"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };